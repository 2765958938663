/**
 * This class function helps in maintaining storing timer variable for Geolocation popup if no activity is selected.
 */
export class TimerStore {
  private static readonly STORAGE_KEY = 'popup_timer_start';
  private static POPUP_DELAY: number;
  private static readonly SHOWN_KEY = 'popup_shown';

  static startTimer(): void {
    const startTime = Date.now();
    localStorage.setItem(this.STORAGE_KEY, startTime.toString());
    localStorage.removeItem(this.SHOWN_KEY);
  }

  static setPopupDelay(delay: number): void {
    this.POPUP_DELAY = delay;
  }

  static getRemainingTime() {
    if (typeof window === 'undefined') {
      return;
    }
    const startTimeStr = localStorage.getItem(this.STORAGE_KEY);
    const hasBeenShown = localStorage.getItem(this.SHOWN_KEY);

    if (hasBeenShown === 'true') {
      return 0;
    }

    if (!startTimeStr) {
      return this.POPUP_DELAY;
    }

    const startTime = parseInt(startTimeStr, 10);
    const elapsedTime = Date.now() - startTime;
    return Math.max(0, this.POPUP_DELAY - elapsedTime);
  }

  static resetTimer(): void {
    localStorage.removeItem(this.STORAGE_KEY);
    localStorage.setItem(this.SHOWN_KEY, 'true');
  }

  static hasBeenShown(): boolean {
    return localStorage.getItem(this.SHOWN_KEY) === 'true';
  }
}
