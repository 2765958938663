import { useOcSelector } from 'src/redux/ocStore';
import { ProductSearchResultModel } from './types';

export type InventoryStatus = 'InStock' | 'LowStock' | 'OutOfStock';

export function useProductInventory(product: ProductSearchResultModel): InventoryStatus {
  const store = useOcSelector((state) => state.storeReducer.selectedStore);

  const quantityAvailable =
    product.supplier_inventory ??
    product.inventoryrecords?.find((x) => x.ownerid === store.storeId)?.quantityavailable ??
    0;

  if (quantityAvailable <= 0) {
    return 'OutOfStock';
  } else if (store.deliveryFee?.lowStock && quantityAvailable <= store.deliveryFee?.lowStock) {
    return 'LowStock';
  } else {
    return 'InStock';
  }
}
