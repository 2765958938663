import { AccordionFacets } from '@sitecore-search/ui';

import { createContext, useState } from 'react';
import useDictionary from 'src/hooks/useDictionary';
import searchResultsTailwind from './SearchResultsTailwind';
import IconHelper from 'src/helpers/commonComponents/IconHelper';

export const FACET_VALUES_PER_PAGE = 8;

interface FacetWrapperContextProps {
  // Couldn't find a good way to make this generic
  items: unknown[];
}

/** Context so we can access the filtered items inside the children */
export const FacetWrapperContext = createContext<FacetWrapperContextProps>({ items: [] });

export interface FacetWrapperProps<T> extends React.PropsWithChildren {
  items: T[];
  getValue: (item: T) => string;
  getText: (item: T) => string;
  selectedFacetValues: string[];
}

/**
 * Facet wrapper to implement search and show more/less functionality
 * This is a wrapper component to reuse between both merged facet and normal ones
 * */
export function FacetWrapper<T>({
  items,
  getValue,
  getText,
  selectedFacetValues,
  children,
}: FacetWrapperProps<T>) {
  const { accordionWrapper, searchInput, iconSearch, showMoreLess } = searchResultsTailwind({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const dictionary = useDictionary();
  const [searchValue, setSearchValue] = useState('');
  const [showPages, setShowPages] = useState(1);

  const filteredValues = items.filter((item) => {
    // Filter on the displayed text
    return getText(item).match(new RegExp(searchValue, 'i'));
  });

  // Sort by selected facets first, but otherwise maintain original order
  filteredValues.sort((a, b) => {
    if (selectedFacetValues.includes(getValue(a)) && !selectedFacetValues.includes(getValue(b)))
      return -1;
    if (selectedFacetValues.includes(getValue(b)) && !selectedFacetValues.includes(getValue(a)))
      return 1;
    return filteredValues.indexOf(a) > filteredValues.indexOf(b) ? 1 : -1;
  });

  // Do pagination after sorting
  const facetValues = filteredValues.slice(0, showPages * FACET_VALUES_PER_PAGE);

  const hasMore = filteredValues.length > facetValues.length;

  const showShowLess = showPages > 1;

  // We only show the search if there are hidden values, or if have a search term since it would be confusing otherwise
  const showSearch = hasMore || searchValue;

  return (
    <AccordionFacets.ValueList className={accordionWrapper()}>
      {showSearch ? (
        <li>
          <input
            aria-label="searchValue"
            type="text"
            value={searchValue}
            placeholder={dictionary.getDictionaryValue('SearchFacetPLP', 'Search')}
            onChange={(e) => setSearchValue(e.target?.value)}
            className={searchInput()}
          />
          <IconHelper icon={'search'} className={iconSearch()} />
        </li>
      ) : null}
      <FacetWrapperContext.Provider value={{ items: facetValues }}>
        <li className={accordionWrapper()}>{children}</li>
      </FacetWrapperContext.Provider>

      {showShowLess ? (
        <li>
          <button aria-label="show less" onClick={() => setShowPages(1)} className={showMoreLess()}>
            - {dictionary.getDictionaryValue('ShowLessFacetsPLP') || 'Show Less'}
          </button>
        </li>
      ) : null}

      {hasMore ? (
        <li>
          <button
            aria-label="show more"
            onClick={() => setShowPages(showPages + 1)}
            className={showMoreLess()}
          >
            + {dictionary.getDictionaryValue('ShowMoreFacetsPLP') || 'Show More'}
          </button>
        </li>
      ) : null}
    </AccordionFacets.ValueList>
  );
}
