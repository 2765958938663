import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import AutoCompleteWrapper from 'src/helpers/Form/AutoCompleteWrapper';
import { StoreLocatorConfig, StoreProps } from './GenericStoreLocator';
import ModalStore from './ModalStore';
import ComponentContext from 'lib/context/ComponentContext';
import { sendStoreGTMData } from 'src/utils/sendGTMEvent';
import { GTMLabels, GTM_EVENT } from 'src/helpers/Constants';
import Loader from 'components/Loader/Loader';
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';
import { storeLocatorGlobalPopupTailwindVariant } from 'tailwindVariants/components/storeLocatorGlobalPopupTailwindVariant';

const StoreLocatorGlobalPopup = ({
  fields,
  options,
  handleSelect,
  address,
  setAddress,
  selectedSuggestions,
  stores,
  isStoreLoading,
  isErrorInStoreData,
  setIsErrorInStoreData,
  setStores,
  totalStores,
  onLoadMoreClick,
  setSelectedSuggestions,
}: {
  fields: StoreLocatorConfig;
  isErrorInStoreData: boolean;
  setIsErrorInStoreData: (value: boolean) => void;
  setStores: (value: []) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any;
  handleSelect: (value: string) => void;
  address: string;
  setAddress: (value: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedSuggestions: any;
  stores: StoreProps[];
  coordinates: { lat: number; lng: number };
  isStoreLoading: boolean;
  totalStores: number;
  onLoadMoreClick: () => void;
  setSelectedSuggestions: ({ Keywords }: { Keywords: string }) => void;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const modalRef = useRef<HTMLDivElement>(null);
  const {
    base,
    modalWrapper,
    headingWrapper,
    heading,
    subHeading,
    formHeading,
    noSearchResultsFoundText,
    input,
    searchButton,
    searchIcon,
    loaderWrapper,
    loadingText,
    storePageArrow,
    loadMoreButton,
    loadMore,
  } = storeLocatorGlobalPopupTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const handleModalClose = () => {
    setIsModalOpen(false);
    const callbackFunctionForStoreLocatorGlobalModal =
      componentContextData?.callbackFunctionForStoreLocatorGlobalModal;
    setcomponentContextData({
      ...componentContextData,
      showStoreLocatorGlobalModal: false,
      callbackFunctionForStoreLocatorGlobalModal: false,
      fromContactUsForm: componentContextData?.fromContactUsForm ? false : '',
    });
    if (callbackFunctionForStoreLocatorGlobalModal) {
      callbackFunctionForStoreLocatorGlobalModal();
    }
  };
  useEffect(() => {
    const handleClickOutsideModal = (event: MouseEvent) => {
      if (
        componentContextData?.showStoreLocatorGlobalModal &&
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).classList.contains('pac-container') &&
        !(event.target as HTMLElement).closest('.pac-container') &&
        !(event.target as HTMLElement).classList.contains('pac-item') &&
        !(event.target as HTMLElement).classList.contains('pac-icon') &&
        !(event.target as HTMLElement).classList.contains('WarningStoreChangeModal') &&
        !(event.target as HTMLElement).classList.contains('cart-warning-popup') &&
        !(event.target as HTMLElement).closest('.WarningStoreChangeModal') &&
        !(event.target as HTMLElement).closest('.modal-wrapper')
      ) {
        handleModalClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutsideModal);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideModal);
    };
  }, []);

  //sending GTM data regarding to Store not found error
  useEffect(() => {
    if (
      isErrorInStoreData &&
      !(stores?.length > 0 && selectedSuggestions?.Keywords?.length > 0 && isStoreLoading)
    ) {
      sendStoreGTMData({ eventName: GTM_EVENT?.storeLocator });
      sendStoreGTMData({ eventName: GTM_EVENT?.storeLocatorError, error: GTMLabels?.storeError });
    }
  }, [isErrorInStoreData, stores, selectedSuggestions, isStoreLoading, GTM_EVENT, GTMLabels]);

  return (
    <div className={base()}>
      <ModalWrapper
        showCloseButtonInModalHeader={false}
        closeModalOnOverlayClick={true}
        onCloseClick={handleModalClose}
        showModal={isModalOpen}
        customPopup={true}
        popupWidth="max-w-[997px]"
        popupSpacing="p-0"
        additionalClassForModal={'!z-[1200]'} // Global Store list
      >
        <div ref={modalRef} className={modalWrapper()}>
          <div className={headingWrapper()}>
            <div>
              <Text tag="p" className={heading()} field={fields?.headerText} />
              <RichText tag="p" className={subHeading()} field={fields?.requiredText} />
            </div>
            <div>
              {!(selectedSuggestions?.Keywords?.length > 0 && stores?.length > 0) && (
                <Text tag="p" className={formHeading()} field={fields?.searchboxText} />
              )}
              {!(selectedSuggestions?.Keywords?.length > 0 && stores?.length > 0) && (
                <AutoCompleteWrapper
                  options={options}
                  addressLine1={address}
                  hideSuggestions={address?.length > Number(fields?.minCharactersForSearch?.value)}
                  onHandleChange={handleSelect}
                >
                  {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                  {({ onAutoSuggestionSubmit }: any) => {
                    return (
                      <form className="relative">
                        <input
                          aria-label="google-auto-suggestion-address"
                          name="google-auto-suggestion-address"
                          id="google-auto-suggestion-address"
                          value={address}
                          placeholder={fields?.searchboxPlaceholderText?.value}
                          className={input({ isError: isErrorInStoreData })}
                          onChange={(e) => {
                            setIsErrorInStoreData(false);
                            setAddress(e?.target?.value);
                          }}
                        />
                        <button
                          aria-label="search"
                          onClick={async (e) => {
                            e.preventDefault();
                            const inputElement = document.getElementById(
                              'google-auto-suggestion-address'
                            ) as HTMLInputElement;
                            if (inputElement) {
                              const inputValue = inputElement?.value;
                              if (inputValue) {
                                // sendStoreGTMData({ eventName: GTM_EVENT?.storeLocator });
                                setAddress(inputValue);
                                setIsErrorInStoreData(false);
                                handleSelect(await onAutoSuggestionSubmit(inputValue));
                              }
                            }
                          }}
                          className={searchButton()}
                        >
                          <IconHelper className={searchIcon()} icon={'search'} />
                        </button>
                      </form>
                    );
                  }}
                </AutoCompleteWrapper>
              )}
              {isErrorInStoreData &&
                !(
                  !isStoreLoading &&
                  stores?.length > 0 &&
                  selectedSuggestions?.Keywords?.length > 0
                ) && (
                  <>
                    {fields?.noSearchResultsFoundText && (
                      <RichText
                        tag="p"
                        className={noSearchResultsFoundText()}
                        field={fields?.noSearchResultsFoundText}
                      />
                    )}
                  </>
                )}
            </div>

            {stores?.length > 0 && selectedSuggestions?.Keywords?.length > 0 && (
              <ModalStore
                stores={stores}
                location={selectedSuggestions?.Keywords}
                fields={fields || {}}
                setStores={setStores}
                // setAddress={setAddress}
                handleModalClose={handleModalClose}
                setSelectedSuggestions={setSelectedSuggestions}
              />
            )}
            {isStoreLoading && selectedSuggestions?.Keywords?.length > 0 && (
              <div className={loaderWrapper()}>
                <Loader />
                <RichText
                  tag="p"
                  field={fields?.loadingNearbyStoresText}
                  className={loadingText()}
                />
              </div>
            )}
            {stores?.length > 0 &&
              totalStores > stores?.length &&
              selectedSuggestions?.Keywords?.length > 0 &&
              fields?.loadMoreButtonText?.value && (
                <div className={loadMoreButton()}>
                  <button aria-label="load more" onClick={onLoadMoreClick} className={loadMore()}>
                    {fields?.loadMoreButtonText?.value}
                    <IconHelper className={storePageArrow() + ' mt-1'} icon={'chevron-right'} />
                  </button>
                </div>
              )}
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

export default StoreLocatorGlobalPopup;
