import {
  GraphQLRequestClientFactoryConfig,
  GraphQLRequestClient,
  getEdgeProxyContentUrl,
} from '@sitecore-jss/sitecore-jss-nextjs/graphql';
import { DocumentNode, OperationDefinitionNode } from 'graphql';
import { JssConfig } from 'lib/config';

/**
 * Creates a new GraphQLRequestClientFactory instance
 * @param config jss config
 * @returns GraphQLRequestClientFactory instance
 */
export const createGraphQLClientFactory = (config: JssConfig) => {
  let clientConfig: GraphQLRequestClientFactoryConfig;

  if (config.sitecoreEdgeContextId) {
    clientConfig = {
      endpoint: getEdgeProxyContentUrl(config.sitecoreEdgeContextId, config.sitecoreEdgeUrl),
    };
  } else if (config.graphQLEndpoint && config.sitecoreApiKey) {
    clientConfig = {
      endpoint: config.graphQLEndpoint,
      apiKey: config.sitecoreApiKey,
    };
  } else {
    throw new Error(
      'Please configure either your sitecoreEdgeContextId, or your graphQLEndpoint and sitecoreApiKey.'
    );
  }

  // To remove logging please revert to previous commit of this file

  const clientFactory = GraphQLRequestClient.createClientFactory(clientConfig);

  // Wrap each GraphQLRequestClient instance's `request` method for logging
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (options?: any) => {
    const client = clientFactory(options);

    const originalRequest = client.request.bind(client);
    client.request = async <T>(...args: Parameters<typeof originalRequest>): Promise<T> => {
      const [query] = args; // Extract the query
      const operationName = extractOperationName(query as DocumentNode); // Get the operation name

      const startTime = Date.now();
      const response = await originalRequest<T>(...args);
      const responseTime = Date.now() - startTime;

      // Log the operation name and response time
      console.log(`GraphQL Request: ${operationName || 'UnnamedQuery'}`);
      console.log(`Response Time: ${responseTime}ms`);

      return response;
    };

    // Helper function to extract the operation name
    function extractOperationName(query: string | DocumentNode): string | null {
      if (typeof query === 'string') {
        const match = query.match(/(?:query|mutation|subscription)\s+(\w+)/);
        return match ? match[1] : null;
      } else if (query && query.definitions) {
        // Check for OperationDefinitionNode and extract name
        const operationDef = query.definitions.find((def) => def.kind === 'OperationDefinition') as
          | OperationDefinitionNode
          | undefined;
        return operationDef?.name ? operationDef.name.value : null;
      }
      return null;
    }

    return client;
  };
};
