export interface AutoshipComponentContextData {
  isSkipClicked?: boolean;
  isSkippedSuccessfully?: boolean;
  skippedDeliveryDate?: string;

  isCancelClicked?: boolean;
  isCancelledSuccessfully?: boolean;
  cancelledAutoshipId?: string;
  autoshipEndDate?: string;
  autoshipCancellationReason?: string;

  subscriptionData?: null | newSubscription;

  openTipModal?: boolean;
  autoshipTipAmount?: number;
  subscriptionIdForTipChange?: string;

  subscriptionId?: string;
  nextDeliveryDate?: string;
  updatedNextDeliveryDate?: string;
}

import { newSubscription } from 'components/MyAutoship/MyAutoship';
import React, { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';

interface AutoshipComponentContextProps {
  autoshipContextData: AutoshipComponentContextData;
  setAutoshipContextData: Dispatch<SetStateAction<AutoshipComponentContextData>>;
}
const AutoshipContext = createContext<AutoshipComponentContextProps>({
  autoshipContextData: {},
  setAutoshipContextData: (_state: AutoshipComponentContextData) => {
    _state;
  },
});
const AutoshipContextProvider = ({ children }: React.PropsWithChildren) => {
  const [autoshipContextData, setAutoshipContextData] = useState<AutoshipComponentContextData>({});

  return (
    <AutoshipContext.Provider value={{ autoshipContextData, setAutoshipContextData }}>
      {children}
    </AutoshipContext.Provider>
  );
};

const useAutoshipContext = () => {
  return useContext(AutoshipContext);
};

export { AutoshipContextProvider, useAutoshipContext };
