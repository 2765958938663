// Tailwind Import
import myCartItems from 'tailwindVariants/components/myItemsTailwindVariant';

import { ComponentProps } from '@sitecore-feaas/clientside';
import { NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';
import {
  FulfillmentType,
  getProductDetailPath,
  getProductPromoTag,
  getTruncatedProductPromoTag,
} from 'src/helpers/Constants';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import { PSP } from 'models/PetSuppliesPlus.Model';
import { useContext, useState } from 'react';
import { getFirstProductImage } from 'src/helpers/productDetailHelper';
import useOcCart from 'src/hooks/useOcCart';
import { useLineItemPriceForUI } from 'src/hooks/useLineItemPriceForUI';
import { useOcSelector } from 'src/redux/ocStore';
import { BuyerProductWithXp, LineItemWithXp } from 'src/redux/xp';
import Link from 'next/link';
import { currencyUsdFormatter } from 'lib/utils/formatters';
import ErrorLabel from '../ErrorLabel/ErrorLabel';
import ComponentContext from 'lib/context/ComponentContext';
import { myFavoriteVariants } from 'tailwindVariants/components/myFavoriteTailwindVariant';
import { productDetailTailwindVariant } from 'tailwindVariants/components/productDetailTailwindVariant';

export type MyItems = React.InputHTMLAttributes<HTMLInputElement> &
  PSP.Sitecore.templates.PetSuppliesPlus.MyItems.Fields.MyItems &
  ComponentProps;

const MyItems = ({ fields }: MyItems): JSX.Element => {
  // Tailwind Variant
  const {
    base,
    title,
    titleWrapper,
    linkWithUnderline,
    // cardWrapper,
  } = myCartItems({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const empty = <div className="myitems" hidden></div>;
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const { getProductLineItems } = useOcCart();
  const productlineitems = getProductLineItems();
  //const myLineItems = useOcSelector((state) => state?.ocCurrentOrder?.lineItems);
  const cart = useOcSelector((state) => state?.ocCurrentOrder?.order);
  const { componentContextData } = useContext(ComponentContext);
  if (
    !myStoreData ||
    Object.keys(myStoreData).length === 0 ||
    !cart ||
    cart.xp?.Fulfillment === FulfillmentType.DFS
  ) {
    return empty;
  }

  return (
    <div className={base()}>
      <div className={titleWrapper()}>
        {/* Title */}
        {fields?.title?.value && <div className={title()}>{fields?.title?.value}</div>}

        {/* Edit Cart */}
        {fields?.link?.value?.href && (
          <div className={linkWithUnderline()}>
            <LinkHelper field={fields?.link} />
          </div>
        )}
      </div>
      <p>
        {componentContextData?.unavailableItemsRemoved == true && (
          <ErrorLabel errorMessage={'checkoutpriceexcludecheck'} isDictionaryKey />
        )}
      </p>
      {/* <div className={cardWrapper()}> */}
      {productlineitems?.map((line) => (
        <MyLineItem key={line.ID} line={line} fields={fields} />
      ))}
      {/* </div> */}
    </div>
  );
};

function MyLineItem({ line, fields }: { line: LineItemWithXp; fields: MyItems['fields'] }) {
  const {
    cartTitle,
    itemWrapper,
    cardInfoWrapper,
    cardTitleQtyContainer,
    // cartTitle,
    cartTitleRed,
    hideInMobile,
    centerInMobile,
    itemImages,
    productWeight,
    priceWrapper,
    productImageWrapper,
    // cardWrapper,
  } = myCartItems({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const { offerLabel } = myFavoriteVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const {
    topTooltipWrapper,
    tooltipContainer,
    topToolTipDescription,
    topTooltipArrowFirstDiv,
    topTooltipArrowSecondDiv,
  } = productDetailTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const priceForCheckout = useLineItemPriceForUI(line, true);
  const [showOfferTooltip, setShowOfferTooltip] = useState<boolean | string>();
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const maxLengthForPromoTag =
    Number(useOcSelector((state) => state?.otherSettingSlice?.data?.maxLengthForPromoTag?.value)) ||
    0;

  const imageData = getFirstProductImage(line?.Product?.xp?.Images);
  const promoTag =
    line?.Product?.xp?.PromoTag &&
    getProductPromoTag(
      line?.Product?.xp?.PromoTag,
      myStoreData?.dataareaid,
      myStoreData.priceGroupArray
    );

  return (
    <div className="relative">
      {showOfferTooltip == line.ProductID && (
        <div
          className={topTooltipWrapper({
            className: '!bottom-[60%] !left-[15%] lg:!left-[11%] -translate-x-1/2',
          })}
        >
          <div className={tooltipContainer()}>
            <div className={topTooltipArrowFirstDiv()}></div>
            <div className={topTooltipArrowSecondDiv()}></div>
            <p className={topToolTipDescription({ className: 'text-center' })}>{promoTag}</p>
          </div>
        </div>
      )}
      <div
        className={
          'lines relative overflow-hidden ' +
          showOfferTooltip +
          clsx(centerInMobile(), itemWrapper())
        }
      >
        {promoTag && (
          <div
            className={offerLabel({})}
            onMouseEnter={() => {
              if (maxLengthForPromoTag && promoTag && promoTag?.length > maxLengthForPromoTag) {
                setShowOfferTooltip(line.ProductID);
              }
            }}
            onMouseLeave={() => {
              if (maxLengthForPromoTag && promoTag && promoTag?.length > maxLengthForPromoTag) {
                setShowOfferTooltip(false);
              }
            }}
          >
            {getTruncatedProductPromoTag(promoTag, maxLengthForPromoTag)}
          </div>
        )}
        <div className={productImageWrapper()}>
          <>
            <div className="product-weight-container">
              <p className={productWeight()}>
                {line?.Product?.xp?.RetailUnit} {line?.Product?.xp?.RetailMeasure}
              </p>
            </div>
            <Link
              aria-label="product image"
              prefetch={false}
              href={getProductDetailPath(line?.Product as BuyerProductWithXp)}
            >
              <NextImage
                className={itemImages()}
                field={{
                  value: {
                    alt: imageData?.Name,
                    src: imageData?.Url,
                  },
                }}
                alt={imageData?.Name}
                width="104"
                height="104"
              />
            </Link>
          </>
        </div>
        <div className={clsx(centerInMobile(), cardInfoWrapper())}>
          <div className={cardTitleQtyContainer()}>
            <div className={clsx(hideInMobile(), cartTitle())}>
              <Link
                aria-label="product image"
                prefetch={false}
                className="hover:!no-underline"
                href={getProductDetailPath(line?.Product as BuyerProductWithXp)}
              >
                {line?.Product?.Name}
              </Link>
            </div>
            <div className={cartTitle()}>
              {fields?.quantity?.value}: {line?.Quantity}
            </div>
          </div>
          <div className={priceWrapper()}>
            {/* List Price */}
            {priceForCheckout?.lineItemMemberPrice !== undefined ? (
              <>
                <div className="flex flex-col gap-0 items-start justify-start shrink-0 relative">
                  <div className={clsx(hideInMobile(), cartTitle())}>
                    {fields?.listTotal?.value}
                  </div>
                  <div className={clsx(cartTitle(), 'line-through')}>
                    {currencyUsdFormatter.format(priceForCheckout?.lineItemListPrice ?? 0)}
                  </div>
                </div>
                <div className="flex flex-col gap-0 items-start justify-start shrink-0 relative">
                  <div className={clsx(hideInMobile(), cartTitle())}>
                    {fields?.memberTotal?.value}
                  </div>
                  <div className={clsx(cartTitle(), cartTitleRed())}>
                    {currencyUsdFormatter.format(priceForCheckout?.lineItemMemberPrice ?? 0)}
                  </div>
                </div>
              </>
            ) : (
              <div className="flex flex-col gap-0 items-start justify-start shrink-0 relative">
                <div className={clsx(hideInMobile(), cartTitle())}>{fields?.listTotal?.value}</div>
                <div className={clsx(cartTitle())}>
                  {currencyUsdFormatter.format(priceForCheckout?.lineItemListPrice ?? 0)}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyItems;
