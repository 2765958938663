import { NextImage, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import React, { useContext } from 'react';
import { StoreLocatorConfig, StoreProps } from './GenericStoreLocator';
import clsx from 'clsx';
import ComponentContext from 'lib/context/ComponentContext';
import { useRouter } from 'next/router';
import { createOrder, retrieveOrder } from 'src/redux/ocCurrentOrder';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { unwrapResult } from '@reduxjs/toolkit';
import { closedText, FulfillmentType, GTM_EVENT } from 'src/helpers/Constants';
import { sendStoreGTMData } from 'src/utils/sendGTMEvent';
import { useBreakpoints } from 'src/utils/breakpoints';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { DateTime } from 'luxon';
import { useGraphQlDataContext } from 'lib/context/GraphQlDataContext';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import { modalStoreTailwindVariant } from 'tailwindVariants/components/modalStoreTailwindVariant';
import useDictionary from 'src/hooks/useDictionary';

const ModalStore = ({
  stores,
  fields,
  location,
  setStores,
  // setAddress,
  handleModalClose,
  setSelectedSuggestions,
}: {
  stores: StoreProps[];
  fields: StoreLocatorConfig;
  location: string;
  // setAddress: (value: string) => void;
  setStores: (value: []) => void;
  handleModalClose: () => void;
  setSelectedSuggestions: ({ Keywords }: { Keywords: string }) => void;
}) => {
  const {
    base,
    mainWrapper,
    storeWrapper,
    storeAddressWrapper,
    storeDetailWrapper,
    storeActionWrapper,
    resultText,
    storeName,
    storeDetails,
    storeAddress,
    openStoreTimingText,
    buttonWrapper,
    buttonWithArrow,
    iconWrapper,
    storeText,
    shopButton,
    storesWrapper,
    myStoreIcon,
    storeViewDetail,
    storeAddressLink,
    noofItemsPerPageText,
    changeLink,
    storePageArrow,
  } = modalStoreTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const router = useRouter();
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const dispatch = useOcDispatch();

  const isEE = useExperienceEditor();

  const { isMobile }: { isMobile: boolean } = useBreakpoints();

  const { storeServices } = useGraphQlDataContext();

  const servicesData = storeServices.reduce((prev, curr) => {
    if (curr?.serviceId?.value) {
      return {
        ...prev,
        [curr.serviceId.value.toString()]: curr?.name?.value,
      };
    }
    return prev;
  }, {} as Record<string, string>);

  const renderServicesList = (servicesList: number[]) => {
    const { serviceList, serviceListWrapper, serviceListBold } = modalStoreTailwindVariant({
      size: { initial: 'mobile', lg: 'desktop' },
    });

    return (
      <>
        {servicesList?.length > 0 && (
          <>
            {fields?.servicesText && (
              <Text tag="p" className={serviceListBold()} field={fields?.servicesText} />
            )}
            <ul className={serviceListWrapper()}>
              {servicesList.map((service, index) => {
                if (servicesData[service?.toString()]) {
                  return (
                    <li key={index} className={serviceList()}>
                      {servicesData[service?.toString()]}
                    </li>
                  );
                } else {
                  return <></>;
                }
              })}
            </ul>
          </>
        )}
      </>
    );
  };
  const renderResultText = () => {
    const stringArr = fields?.noofItemsPerPageText?.value
      ?.replace('{number}', `${stores?.length}`)
      ?.split('{location}');
    return stringArr && stringArr?.length > 1 ? (
      <>
        {stringArr[0]}
        <strong>
          {location?.length > 0 ? `"${location}"` : `"${myStoreData?.city}, ${myStoreData?.state}"`}
        </strong>
        {stringArr[1]}
      </>
    ) : (
      stringArr && stringArr[0]
    );
  };
  const currentStoreId = componentContextData?.fromContactUsForm
    ? componentContextData?.contactUsFormValues?.StoreId
    : myStoreData?.storeId;

  const { getDictionaryValue } = useDictionary();
  const renderViewStoreLink = () => (
    <LinkHelper
      className={changeLink()}
      onClick={(e) => {
        e.preventDefault();
        setStores([]);
        // setAddress('');
        setSelectedSuggestions({ Keywords: '' });
      }}
      field={{ value: { href: '/', text: fields?.changeText?.value } }}
    />
  );
  return (
    <div className={mainWrapper()}>
      {stores?.length > 0 && (
        <div className={base()}>
          {fields?.noofItemsPerPageText && stores?.length > 0 && (
            <div className={noofItemsPerPageText()}>
              {isEE ? (
                <>
                  <RichTextHelper
                    tag="p"
                    field={fields?.noofItemsPerPageText}
                    updatedField={{
                      value: fields?.noofItemsPerPageText?.value
                        ?.replace(
                          '{location}',
                          `<strong>"${
                            location?.length > 0
                              ? location
                              : `${myStoreData?.city}, ${myStoreData?.state}`
                          }"</strong>`
                        )
                        ?.replace('{number}', `${stores?.length}`),
                    }}
                    className={resultText()}
                  />
                  {renderViewStoreLink()}
                </>
              ) : (
                <>
                  {renderResultText()}
                  {renderViewStoreLink()}
                </>
              )}
            </div>
          )}
          <div className={storesWrapper()}>
            {stores.map((store, index) => {
              const storeTimings = store?.xp_storehours?.find(
                (dayData) => dayData?.Day === new Date()?.getDay() + 1
              );
              return (
                <div
                  id={`google_map_store_${store?.storeid}`}
                  key={index}
                  className={storeWrapper()}
                >
                  <div className={storeDetailWrapper()}>
                    <div className={storeDetails()}>
                      <div>
                        <div className="flex flex-wrap">
                          <p className={storeName()}>
                            {index + 1}. {store?.firstname}{' '}
                          </p>
                          {fields?.viewStorePageButtonText?.value && (
                            <LinkHelper
                              className={storeViewDetail()}
                              onClick={(e) => {
                                e.preventDefault();
                                router.push(
                                  `/store/${store?.state?.toLowerCase()}/${store?.city?.toLowerCase()}/${store?.storeid?.toLowerCase()}-${store?.city?.toLowerCase()}/${store?.storeid?.toLowerCase()}`?.replaceAll(
                                    ' ',
                                    '-'
                                  )
                                );
                                handleModalClose();
                              }}
                              field={{
                                value: {
                                  text: fields?.viewStorePageButtonText?.value || '',
                                  href: `/store/${store?.state?.toLowerCase()}/${store?.city?.toLowerCase()}/${store?.storeid?.toLowerCase()}-${store?.city?.toLowerCase()}/${store?.storeid?.toLowerCase()}`?.replaceAll(
                                    ' ',
                                    '-'
                                  ),
                                },
                              }}
                            />
                          )}
                        </div>

                        <div className={storeAddressWrapper()}>
                          <Text
                            className={storeAddress()}
                            tag="p"
                            field={{ value: store?.street1 }}
                          />
                          <Text
                            className={storeAddress()}
                            tag="p"
                            field={{ value: `${store.city}, ${store.state} ${store?.zip}` }}
                          />
                          {store?.phone && (
                            <LinkHelper
                              className={clsx(storeAddress(), storeAddressLink())}
                              field={{
                                value: {
                                  text: store.phone,
                                  href: `tel:${store.phone}`,
                                },
                              }}
                            />
                          )}
                        </div>
                      </div>
                      {isMobile && fields?.storeLocatorRemainingOpenTimeText && (
                        <RichTextHelper
                          tag="p"
                          field={fields?.storeLocatorRemainingOpenTimeText}
                          updatedField={{
                            value:
                              storeTimings && storeTimings?.OpenTime && storeTimings?.CloseTime
                                ? storeTimings?.OpenTime === storeTimings?.CloseTime
                                  ? closedText
                                  : fields?.storeLocatorRemainingOpenTimeText?.value?.replace(
                                      '{storeLocatorRemainingOpenTime}',
                                      `${
                                        storeTimings?.OpenTime
                                          ? DateTime.fromFormat(
                                              storeTimings.OpenTime,
                                              'HH:mm:ss'
                                            ).toFormat('h a')
                                          : ''
                                      } - ${
                                        storeTimings?.CloseTime
                                          ? DateTime.fromFormat(
                                              storeTimings.CloseTime,
                                              'HH:mm:ss'
                                            ).toFormat('h a')
                                          : ''
                                      }`
                                    ) || ''
                                : closedText,
                          }}
                          className={openStoreTimingText()}
                        />
                      )}
                      <div>{renderServicesList(store?.xp_storeservices)}</div>
                    </div>
                    <div className={storeActionWrapper()}>
                      {!isMobile && fields?.storeLocatorRemainingOpenTimeText && (
                        <RichTextHelper
                          tag="p"
                          field={fields?.storeLocatorRemainingOpenTimeText}
                          updatedField={{
                            value:
                              storeTimings && storeTimings?.OpenTime && storeTimings?.CloseTime
                                ? storeTimings?.OpenTime === storeTimings?.CloseTime
                                  ? closedText
                                  : fields?.storeLocatorRemainingOpenTimeText?.value?.replace(
                                      '{storeLocatorRemainingOpenTime}',
                                      `${
                                        storeTimings?.OpenTime
                                          ? DateTime.fromFormat(
                                              storeTimings.OpenTime,
                                              'HH:mm:ss'
                                            ).toFormat('h a')
                                          : ''
                                      } - ${
                                        storeTimings?.CloseTime
                                          ? DateTime.fromFormat(
                                              storeTimings.CloseTime,
                                              'HH:mm:ss'
                                            ).toFormat('h a')
                                          : ''
                                      }`
                                    ) || ''
                                : closedText,
                          }}
                          className={openStoreTimingText()}
                        />
                      )}

                      <div className={buttonWrapper()}>
                        {Number(currentStoreId).toString() === Number(store?.storeid).toString() ? (
                          <>
                            {fields?.myStoreText?.value && (
                              <div className={iconWrapper()}>
                                <NextImage className={myStoreIcon()} field={fields?.myStoreIcon} />
                                <Text
                                  field={{ value: fields?.myStoreText?.value }}
                                  tag="p"
                                  className={storeText()}
                                ></Text>
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {!componentContextData?.fromContactUsForm ? (
                              <>
                                {fields?.shopThisStoreText?.value && (
                                  <LinkHelper
                                    className={shopButton()}
                                    onClick={async (e) => {
                                      e.preventDefault();
                                      if (store?.storeid) {
                                        setcomponentContextData({
                                          ...componentContextData,
                                          storeId: store?.storeid,
                                          showStoreLocatorGlobalModal: true,
                                          storeCount: stores?.length,
                                          callBackFunctionAfterStoreChange: async () => {
                                            const retrieveStoreOrder = await dispatch(
                                              retrieveOrder()
                                            );
                                            const responsesStoreOrder =
                                              unwrapResult(retrieveStoreOrder);
                                            if (responsesStoreOrder === undefined) {
                                              await dispatch(
                                                createOrder(
                                                  localStorage.getItem(
                                                    'selected_fulfillment_Method'
                                                  ) === FulfillmentType?.DFS
                                                )
                                              );
                                              localStorage.removeItem(
                                                'selected_fulfillment_Method'
                                              );
                                            } else {
                                              sendStoreGTMData({
                                                eventName: GTM_EVENT?.shopThisStore,
                                                storeId: store?.storeid,
                                                storesShown: stores?.length?.toString(),
                                              });
                                              await dispatch(retrieveOrder());
                                            }
                                            handleModalClose();
                                          },
                                        });
                                      }
                                    }}
                                    field={{
                                      value: { href: '/', text: fields?.shopThisStoreText?.value },
                                    }}
                                  />
                                )}
                              </>
                            ) : (
                              <>
                                <LinkHelper
                                  className={shopButton()}
                                  field={{
                                    value: { href: '/', text: getDictionaryValue('SelectStore') },
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (store?.storeid) {
                                      setTimeout(() => {
                                        componentContextData?.contactUsFormSetFieldFn(
                                          'Store',
                                          `${store?.state} - ${store?.firstname} (${store?.city})`
                                        );
                                        componentContextData?.contactUsFormSetFieldFn(
                                          'StoreId',
                                          Number(store?.storeid).toString()
                                        );
                                        handleModalClose();
                                      }, 1000);
                                    }
                                  }}
                                />
                              </>
                            )}
                          </>
                        )}
                        {store?.xp_storetype === 1 ? (
                          <LinkHelper
                            className={buttonWithArrow()}
                            field={{
                              value: {
                                href: fields?.jobOpportunitiesURL?.value?.href,
                                target: fields?.jobOpportunitiesURL?.value?.target ?? '_blank',
                                text: fields?.jobOpportunitiesText?.value,
                              },
                            }}
                          >
                            <>
                              {fields?.jobOpportunitiesURL?.value?.href &&
                                fields?.jobOpportunitiesText?.value && (
                                  <>
                                    <Text field={{ value: fields?.jobOpportunitiesText?.value }} />
                                    <IconHelper
                                      className={storePageArrow()}
                                      icon={'chevron-right'}
                                    />
                                  </>
                                )}
                            </>
                          </LinkHelper>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalStore;
