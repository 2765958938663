// Global

import { Field as FormikField, Formik, Form as FormikForm, FormikHelpers } from 'formik';

// Utils and Form Helper
import TextAreaField from 'src/helpers/Form/TextAreaField';
import TextField from 'src/helpers/Form/TextField';
import { FormFieldsProps, transformData } from 'src/utils/formUtils';
// Tailwind Import
import pickUpAddress from 'tailwindVariants/components/pickupTailwindVariant';
import CheckboxField from 'src/helpers/Form/CheckboxField';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import { ComponentProps } from 'lib/component-props';
import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { patchOrder } from 'src/redux/ocCurrentOrder';
import { FulfillmentType, GTMLabels, GTM_EVENT } from 'src/helpers/Constants';
import { useCheckoutFormContext } from 'lib/context/CheckoutFormContext';
import PhoneField, { formatPhoneForDisplay } from 'src/helpers/Form/PhoneField';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import { updateUser } from 'src/redux/ocUser';
import { LineItemWithXp, MeUserWithXp, OrderWithXp, PickupInfo } from 'src/redux/xp';
import { getGTMSessionStorage, sendProductsPromotion } from 'src/utils/sendGTMEvent';
import { ProductSearchResultModelWithVariants } from 'src/helpers/search/SearchResults/types';
import useOcCart from 'src/hooks/useOcCart';
import Loader from 'components/Loader/Loader';
import useDictionary from 'src/hooks/useDictionary';
import { formatPhoneForSaving } from 'lib/utils/string-utils';
import { useCartPriceForUI } from 'src/hooks/useCartPriceForUI';
import { hasValidBillingAddress } from 'lib/utils/checkout/section-validators';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import { PaymentErrorLabel } from '../CheckoutPayment/CheckoutPayment';

export type PickupAddressProps = React.InputHTMLAttributes<HTMLInputElement> &
  ComponentProps & {
    fields: {
      data: {
        data: {
          title: Field<string>;
          shortDescription: Field<string>;
          submitButtonText: Field<string>;
          successMessage: Field<string>;
          successRedirectUrl?: { jsonValue: LinkField };
          failureMessage?: Field<string>;
          consentMessage?: Field<string>;
          errors: Field<string>;
          formFields: {
            targetItems: Array<FormFieldsProps>;
          };
        };
        content: {
          editLabel: Field<string>;
          pickupInfo: Field<string>;
          pickupByLabel: Field<string>;
          pickupFromLabel: Field<string>;
          instructionLabel: Field<string>;
          pickupInfoTitle?: Field<string>;
        };
      };
    };
  };

interface FormValuesTypes {
  Email?: string;
  Message?: string;
  PhoneNumber?: string;
  PhoneTypeCheckbox?: string;
  IamPickingUp?: string;
  FirstName?: string;
  LastName?: string;
  savetoprofile?: string;
}

const PickupAddress = ({ fields }: PickupAddressProps): JSX.Element => {
  // Tailwind Variant
  const {
    base,
    titleWrapper,
    title,
    pickupAddressWrapper,
    addressBlock,
    informationTitle,
    form,
    radioIcon,
    pickupInformationContainer,
    radioButtonsWrapper,
    radioButtonWrapper,
    radioField,
    validFieldInfo,
    info,
    inlineFieldWrapper,
    radioAsCheckboxWrapper,
    labels,
    checkmarkIcon,
    field,
    inlineFields,
    submitBtn,
    loaderWrapper,
    fieldWrapper,
    addressBlockHeadline,
    blockWithMargin,
    linkWithUnderline,
    addressInfo,
    personalInfo,
  } = pickUpAddress({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  // OC Dispatch
  const dispatch = useOcDispatch();
  const {
    checkoutContextData,
    updateCheckoutContextData,
    expandedStep,
    activateStep,
    goToNextStep,
    pickupAddressForm,
  } = useCheckoutFormContext();
  const isAnonymous = useOcSelector((s) => s?.ocAuth?.isAnonymous);

  // Get form-fields
  const transFormFields = transformData(fields.data?.data?.formFields);

  // Radio for pickup information
  const radioButtonData = fields.data?.data?.formFields?.targetItems?.find(
    (item) => item?.id?.value === 'IamPickingUp'
  )?.checkboxvalues?.values;

  // Checkbox treated as radio
  const checkboxAsRadioButtonData = fields.data?.data?.formFields?.targetItems?.find(
    (item) => item?.id?.value === 'PhoneTypeCheckbox'
  )?.checkboxvalues?.values;
  const content = fields.data?.content;
  const empty = <div id="pickupaddress" className="pickupaddress" hidden></div>;
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const cart = useOcSelector((state) => state?.ocCurrentOrder?.order);
  const pickupInformation = cart?.xp?.PickupInfo;
  const userDetails = useOcSelector((state) => state?.ocUser?.user);
  const { getProductLineItems } = useOcCart();
  const productlineitems = getProductLineItems();

  const userPickupAddress = {
    ID: userDetails?.ID,
    Phone: userDetails?.Phone,
    xp: { PickupInstruction: userDetails?.xp?.PickupInstruction },
  };

  const [loading, setLoading] = useState<boolean>(false);

  const collapse = expandedStep !== 'pickup';

  const [pickupToMe, setPickupToMe] = useState<boolean>(
    pickupInformation ? !!pickupInformation?.SelfPickup : true
  );

  const ocCurrentOrder = useOcSelector((state) => state?.ocCurrentOrder);

  // to re-render based on SelfPickup option on first load and Edit:
  useEffect(() => {
    if (pickupInformation) {
      setPickupToMe(!!pickupInformation.SelfPickup);
    }
  }, [pickupInformation]);

  // To toggle FName and LName based on selection
  const handleDeliveryInfo = (
    event: React.MouseEvent,
    setValues: FormikHelpers<FormValuesTypes>['setValues']
  ) => {
    if ((event.target as HTMLInputElement)?.value == 'true') {
      setPickupToMe(true);
      if (!pickupInformation) {
        if (isAnonymous) {
          setValues((prevData) => ({
            ...prevData,
            FirstName: '',
            LastName: '',
            Email: '',
            PhoneNumber: '',
            PhoneTypeCheckbox: 'true',
            Message: '',
          }));
        } else {
          setValues((prevData) => ({
            ...prevData,
            FirstName: userDetails?.FirstName ?? '',
            LastName: userDetails?.LastName ?? '',
            Email: userDetails?.Email ?? '',
            PhoneNumber: formatPhoneForDisplay(userDetails?.Phone ?? userDetails?.xp?.HomePhone),
            PhoneTypeCheckbox: userDetails?.xp?.IsMobile?.toString() ?? 'true',
            Message: userPickupAddress?.xp?.PickupInstruction ?? '',
          }));
        }
      } else {
        if (pickupInformation?.SelfPickup) {
          // should be pulled from saved information as pickUpToMe:true
          setValues((prevData) => ({
            ...prevData,
            FirstName: pickupInformation?.FirstName || (!isAnonymous ? userDetails?.FirstName : ''),
            LastName: pickupInformation?.LastName || (!isAnonymous ? userDetails?.LastName : ''),
            Email: pickupInformation?.Email || (!isAnonymous ? userDetails?.Email : ''),
            PhoneNumber:
              formatPhoneForDisplay(pickupInformation?.PhoneNumber) ||
              (!isAnonymous
                ? formatPhoneForDisplay(userDetails?.Phone ?? userDetails?.xp?.HomePhone)
                : ''),
            PhoneTypeCheckbox:
              pickupInformation?.IsMobile.toString() ||
              (!isAnonymous ? userDetails?.xp?.IsMobile?.toString() : 'true'),
            Message: userPickupAddress?.xp?.PickupInstruction ?? '',
          }));
        } else {
          // should be null as the saved data was pickUpToMe:false
          setValues((prevData) => ({
            ...prevData,
            FirstName: '',
            LastName: '',
            Email: !isAnonymous ? userDetails?.Email : '',
            PhoneNumber: !isAnonymous
              ? formatPhoneForDisplay(userDetails?.Phone ?? userDetails?.xp?.HomePhone)
              : '',
            PhoneTypeCheckbox: !isAnonymous ? userDetails?.xp?.IsMobile?.toString() : 'true',
            Message: userPickupAddress?.xp?.PickupInstruction ?? '',
          }));
        }
      }
    } else {
      setPickupToMe(false);

      if (pickupInformation?.SelfPickup) {
        // if true, values should be null
        setValues((prevData) => ({
          ...prevData,
          FirstName: '',
          LastName: '',
          Email: '',
          PhoneNumber: '',
          PhoneTypeCheckbox: 'true',
          Message: '',
        }));
      } else {
        // if false, values should be fetched from pickupInfo
        setValues((prevData) => ({
          ...prevData,
          FirstName: pickupInformation?.FirstName || '',
          LastName: pickupInformation?.LastName || '',
          Email: pickupInformation?.Email || '',
          PhoneNumber: formatPhoneForDisplay(pickupInformation?.PhoneNumber) || '',
          PhoneTypeCheckbox: pickupInformation?.IsMobile.toString() || 'true',
          Message: pickupInformation?.SpecialInstruction ?? '',
        }));
      }
    }
  };

  //set initial componentContextDataValues
  useEffect(() => {
    updateCheckoutContextData({ warningProfileUpdate: false });
  }, [checkoutContextData?.setSubmitting]);

  useEffect(() => {
    if (pickupInformation?.SelfPickup) {
      setPickupToMe(true);
    }
  }, [pickupInformation?.SelfPickup]);

  // Function call to submit/save the delivery address[ShippingAddress] to the currentOrder.
  const submitForm = async (values: FormValuesTypes | undefined, fromUserAction: boolean) => {
    try {
      setLoading(true);
      const isMobile = values?.PhoneTypeCheckbox === 'true';
      const selfPick = values?.IamPickingUp === 'true';
      const pickupinfo: PickupInfo = {
        StoreId: myStoreData.storeId as string,
        FirstName: values?.FirstName ?? '',
        LastName: values?.LastName ?? '',
        Email: values?.Email ?? '',
        PhoneNumber: formatPhoneForSaving(values?.PhoneNumber),
        IsMobile: isMobile,
        SelfPickup: selfPick,
        SpecialInstruction: values?.Message ?? '',
      };

      // keep this commented. currently handled during setting up on the form and setValues
      if (pickupToMe && !isAnonymous) {
        // fetch names from user's details:
        pickupinfo.FirstName = userDetails?.FirstName ?? '';
        pickupinfo.LastName = userDetails?.LastName ?? '';
      }
      const request: OrderWithXp = {
        xp: {
          PickupInfo: pickupinfo,
          Fulfillment: FulfillmentType.BOPIS,
        },
      };
      // Send data to OC to save Pickup address
      await dispatch(
        patchOrder({
          request,
          // If it's already BOPIS, we can defer calculate
          // Since pickup address doesn't change calculation
          deferCalculate: cart?.xp?.Fulfillment === FulfillmentType.BOPIS,
        })
      );

      if (!hasValidBillingAddress(cart)) {
        updateCheckoutContextData({
          setSubmitting: undefined,
        });
      }

      if (fromUserAction) {
        goToNextStep('pickup');
      }

      setLoading(false);
    } catch (error) {
      console.error('saveShippingAddress: ', error);
    }
  };

  //for saving pickupaddress in home
  const saveUserProfile = async (values: FormValuesTypes) => {
    setLoading(true);
    const request: MeUserWithXp = {
      ID: userPickupAddress?.ID,
      ...(values?.PhoneTypeCheckbox === 'true' && {
        Phone: formatPhoneForSaving(values?.PhoneNumber),
      }),
      xp: {
        PickupInstruction: values?.Message as string,
        ...(values?.PhoneTypeCheckbox !== 'true' && {
          HomePhone: formatPhoneForSaving(values?.PhoneNumber),
        }),
      },
    };
    request && (await dispatch(updateUser(request)));
    setLoading(false);
  };

  const initialValues = useInitialValues();

  const [formValues, setFormValues] = useState<FormValuesTypes>(initialValues);

  useEffect(() => {
    if (checkoutContextData?.setSubmitting !== undefined) {
      if (checkoutContextData?.setSubmitting === true) {
        updateCheckoutContextData({ setSubmitting: undefined });
        saveUserProfile(formValues);
        submitForm(formValues, true);
      } else {
        updateCheckoutContextData({ setSubmitting: undefined });
        submitForm(formValues, true);
      }
    }
  }, [checkoutContextData?.setSubmitting]);

  const openDeliveryForm = async () => {
    sendBeginCheckout();

    // Remove Loader and Restore Submit Button
    setLoading(false);

    activateStep('pickup');
  };

  //submitting PickupAddress
  const submitPickupAddress = async (values: FormValuesTypes) => {
    setFormValues(values);
    if (values?.savetoprofile && !isAnonymous) {
      if (
        formatPhoneForSaving(userPickupAddress?.Phone) !==
          formatPhoneForSaving(values?.PhoneNumber) ||
        (userPickupAddress?.xp?.PickupInstruction &&
          userPickupAddress?.xp?.PickupInstruction !== values?.Message)
      ) {
        updateCheckoutContextData({
          warningProfileUpdate: true,
          isPickup: true,
        });
      } else {
        saveUserProfile(values);
        submitForm(values, true);
      }
    } else {
      submitForm(values, true);
    }
  };

  const { getDictionaryValue } = useDictionary();

  const { sendBeginCheckout } = usePickupGtm(productlineitems);

  const pickupInfoValid =
    pickupInformation?.FirstName &&
    pickupInformation?.LastName &&
    pickupInformation?.Email &&
    pickupInformation?.PhoneNumber;

  const initialValuesValid =
    initialValues?.FirstName &&
    initialValues?.LastName &&
    initialValues?.Email &&
    initialValues?.PhoneNumber;
  /**
   * Logic to automatically save the Pickup form info if user is authenticated
   */
  useEffect(() => {
    if (
      !isAnonymous &&
      ocCurrentOrder?.initialized &&
      ocCurrentOrder.order?.xp?.Fulfillment === 'BOPIS'
    ) {
      // Only submit if we don't have valid info on cart, but initial values are valid
      // There are edge case where we get temporarily invalid info due to certain things not loaded yet
      // and we don't want to save that
      if (!pickupInfoValid && initialValuesValid) {
        // Extract data from user Details and save to shippingAddress.
        submitForm(initialValues, false); // call save shippingAddress:
      }
    }
  }, [
    ocCurrentOrder?.initialized,
    isAnonymous,
    ocCurrentOrder.order?.xp?.Fulfillment,
    pickupInfoValid,
    initialValuesValid,
  ]);

  if (!myStoreData || !cart || cart?.xp?.Fulfillment === FulfillmentType.DFS) {
    return empty;
  }

  return (
    <>
      <PaymentErrorLabel />

      <div id="pickupaddress" className={'pickupaddress ' + base({ isCollapsed: collapse })}>
        <div className={titleWrapper()}>
          <div className={title({ isCollapsed: collapse })}>
            1. {fields?.data?.data?.title?.value}
          </div>
          {collapse && (
            <div className={linkWithUnderline() + ' cursor-pointer'} onClick={openDeliveryForm}>
              {content?.editLabel?.value ?? 'Edit'}
            </div>
          )}
        </div>
        <div className={pickupAddressWrapper()}>
          <div className={personalInfo()}>
            <div className={blockWithMargin()}>
              <div className={clsx(addressBlockHeadline(), addressBlock())}>
                {' '}
                {content?.pickupFromLabel?.value ?? 'Pick From:'}
              </div>
              <div className={addressBlock()}>
                <span className="block">
                  {myStoreData?.storeName}, {myStoreData?.street1}
                </span>
                <span className="block">
                  {myStoreData?.city}, {myStoreData?.state}
                </span>
              </div>
            </div>
            <div className={blockWithMargin()}>
              <div className={addressBlock()}>
                <LinkHelper
                  className={linkWithUnderline()}
                  field={{
                    value: {
                      href: `tel:${myStoreData?.phone}`,
                      text: `${myStoreData?.phone}`,
                    },
                  }}
                />
              </div>
            </div>
            {collapse && (
              <div className="flex">
                <div className={blockWithMargin()}>
                  <div className={addressBlock()}>
                    {content?.pickupByLabel?.value ?? 'Pickedup By:'} {initialValues?.FirstName}{' '}
                    {initialValues?.LastName}
                  </div>
                  {initialValues?.PhoneNumber && (
                    <LinkHelper
                      className={linkWithUnderline()}
                      field={{
                        value: {
                          href: `tel:${initialValues?.PhoneNumber}`,
                          text: `${formatPhoneForDisplay(initialValues?.PhoneNumber)}`,
                        },
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          <div className={addressInfo()}>
            <RichTextHelper className={addressInfo()} field={content?.pickupInfo} />
          </div>
        </div>
        <div className={personalInfo()}>
          {initialValues?.Message && (
            <div className="block">
              <div className={addressBlock()}>
                {content?.instructionLabel?.value ?? 'Special instructions'}
              </div>
              <div className="break-words">{initialValues?.Message}</div>
            </div>
          )}
        </div>

        {!collapse && (
          <Formik
            initialValues={initialValues}
            innerRef={pickupAddressForm}
            onSubmit={(values: FormValuesTypes) => {
              submitPickupAddress(values);
            }}
          >
            {({ setValues }) => (
              <FormikForm className={pickupInformationContainer()}>
                <div className={radioButtonsWrapper()}>
                  <div className={informationTitle()}>{content?.pickupInfoTitle?.value}</div>
                  <div
                    role="group"
                    aria-labelledby="my-radio-group"
                    className={radioButtonsWrapper()}
                  >
                    {radioButtonData?.map((radioButton, index: number) => {
                      return (
                        <label key={index} className={radioButtonWrapper()}>
                          <FormikField
                            type="radio"
                            className={radioField()}
                            name="IamPickingUp"
                            value={radioButton?.value}
                            onClick={(event: React.MouseEvent) =>
                              handleDeliveryInfo(event, setValues)
                            }
                          />
                          {radioButton?.name}
                          <div className={radioIcon()}></div>
                        </label>
                      );
                    })}
                  </div>
                </div>
                <div className={pickupInformationContainer()}>
                  <div className={form({ isCollapsed: collapse })}>
                    {/* Fields marked with an asterisk * .... */}
                    {fields?.data?.data?.shortDescription?.value && (
                      <div className={fieldWrapper()}>
                        <div className={validFieldInfo()}>
                          {fields?.data?.data?.shortDescription?.value}
                        </div>
                      </div>
                    )}

                    {/* First Name and Last Name */}
                    {isAnonymous && (
                      <div className={inlineFields()}>
                        {transFormFields.FirstName && (
                          <div className={fieldWrapper()}>
                            <TextField {...transFormFields.FirstName} />
                          </div>
                        )}
                        {transFormFields.LastName && (
                          <div className={fieldWrapper()}>
                            <TextField {...transFormFields.LastName} />
                          </div>
                        )}
                      </div>
                    )}
                    {!isAnonymous && !pickupToMe && (
                      <div className={inlineFields()}>
                        {transFormFields.FirstName && (
                          <div className={fieldWrapper()}>
                            <TextField {...transFormFields.FirstName} />
                          </div>
                        )}
                        {transFormFields.LastName && (
                          <div className={fieldWrapper()}>
                            <TextField {...transFormFields.LastName} />
                          </div>
                        )}
                      </div>
                    )}

                    {/* Email Field */}
                    {transFormFields.Email && (
                      <div className={fieldWrapper()}>
                        <TextField {...transFormFields.Email} />
                      </div>
                    )}

                    {/* Telephone - Mobile Phone & Home Phone */}
                    {transFormFields.PhoneNumber && (
                      <div className={inlineFieldWrapper()}>
                        <div className="inlineWrapper">
                          <PhoneField {...transFormFields.PhoneNumber} />
                        </div>
                        <div className={radioAsCheckboxWrapper()}>
                          {checkboxAsRadioButtonData?.map((element, index: number) => {
                            return (
                              <label className={labels()} key={index}>
                                <FormikField
                                  type="radio"
                                  className={field({ className: 'rounded-full' })}
                                  name="PhoneTypeCheckbox"
                                  value={element?.value}
                                />
                                {element.name}
                                <IconHelper className={checkmarkIcon()} icon={'icon-checkmark'} />
                              </label>
                            );
                          })}
                        </div>
                      </div>
                    )}

                    {/* Special Information TextBlock */}
                    <div className={fieldWrapper()}>
                      {transFormFields.Message && <TextAreaField {...transFormFields.Message} />}
                      {fields?.data?.data?.consentMessage && (
                        <span className={info()}>{fields?.data?.data?.consentMessage?.value}</span>
                      )}
                    </div>

                    {/* Save to Profile */}
                    {!isAnonymous && transFormFields.savetoprofile && (
                      <div className={fieldWrapper()}>
                        {<CheckboxField {...transFormFields.savetoprofile} singleCheckbox={true} />}
                      </div>
                    )}
                    <div className={fieldWrapper()}>
                      {loading ? (
                        <div className={loaderWrapper()}>
                          <Loader />
                          {getDictionaryValue('ScheduleAppointmentsStep2SelectDogInProgressText')}
                        </div>
                      ) : (
                        <>
                          {fields.data?.data?.submitButtonText?.value && (
                            <button aria-label="submit" className={submitBtn()} type="submit">
                              {fields.data?.data?.submitButtonText?.value}
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </FormikForm>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};
export default PickupAddress;

function useInitialValues() {
  const isAnonymous = useOcSelector((s) => s?.ocAuth?.isAnonymous);
  const pickupInformation = useOcSelector((state) => state?.ocCurrentOrder?.order?.xp?.PickupInfo);
  const userDetails = useOcSelector((state) => state?.ocUser?.user);

  if (pickupInformation) {
    // already have saved pickupInfo
    return {
      FirstName: pickupInformation?.FirstName ?? '',
      LastName: pickupInformation?.LastName ?? '',
      Email: pickupInformation?.Email ?? '',
      PhoneNumber: formatPhoneForDisplay(pickupInformation?.PhoneNumber),
      PhoneTypeCheckbox: pickupInformation?.IsMobile?.toString() ?? 'true',
      IamPickingUp: (!pickupInformation || pickupInformation?.SelfPickup?.toString()) ?? 'true',
      Message: pickupInformation?.SpecialInstruction ?? '',
      savetoprofile: 'true',
    };
  }

  if (isAnonymous) {
    // for guest
    return {
      FirstName: '',
      LastName: '',
      Email: '',
      PhoneNumber: '',
      PhoneTypeCheckbox: 'true',
      IamPickingUp: 'true',
      Message: '',
      savetoprofile: 'false',
    };
  }

  // when no pickupInfo is saved prior to this.
  return {
    FirstName: userDetails?.FirstName ?? '',
    LastName: userDetails?.LastName ?? '',
    Email: userDetails?.Email ?? '',
    PhoneNumber: formatPhoneForDisplay(userDetails?.Phone ?? userDetails?.xp?.HomePhone),
    PhoneTypeCheckbox: userDetails?.xp?.IsMobile?.toString() ?? 'true',
    IamPickingUp: 'true',
    Message: userDetails?.xp?.PickupInstruction ?? '',
    savetoprofile: 'true',
  };
}

function usePickupGtm(productlineitems: LineItemWithXp[]) {
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const currentOrder = useOcSelector((state) => state?.ocCurrentOrder);
  // const isAnonymous = useOcSelector((s) => s?.ocAuth?.isAnonymous);

  const cart = currentOrder?.order;
  const pickup = cart?.xp?.Fulfillment === FulfillmentType.BOPIS;

  const { currentPage, pageItem, position } = getGTMSessionStorage();

  const cartPricing = useCartPriceForUI(currentOrder);

  const observerRef = useRef<MutationObserver | null>(null);

  useEffect(() => {
    const targetNode = document.body; // You might want to use a more specific target
    const config = { childList: true, subtree: true };

    const productData = productlineitems?.map((lineItem) => {
      const products = {
        ...lineItem?.Product,
        quantity: lineItem?.Quantity,
        BasePrice: lineItem?.UnitPrice,
        listPrice: lineItem?.UnitPrice,
      };
      return products;
    });

    const checkGtmLoad = () => {
      const isGTMLoad =
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        typeof window !== 'undefined' && (window as any)['google_tag_manager']?.dataLayer?.gtmLoad;

      if (productData && isGTMLoad && cart && cart?.xp?.Fulfillment === FulfillmentType.BOPIS) {
        sendBeginCheckout();
        observerRef.current?.disconnect();
      }
    };

    const callback = (mutationsList: MutationRecord[]) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          checkGtmLoad();
          break;
        }
      }
    };

    observerRef.current = new MutationObserver(callback);
    observerRef.current.observe(targetNode, config);

    checkGtmLoad();

    return () => {
      observerRef.current?.disconnect();
    };
  }, [cart?.xp?.Fulfillment]);

  // useEffect(() => {
  //   const productData = productlineitems?.map((lineItem) => {
  //     const products = {
  //       ...lineItem?.Product,
  //       quantity: lineItem?.Quantity,
  //       BasePrice: lineItem?.UnitPrice,
  //     };
  //     return products;
  //   });

  //   isAnonymous &&
  //     pickup &&
  //     productData &&
  //     sendProductsPromotion({
  //       eventName: GTM_EVENT?.beginCheckout,
  //       data: productData as unknown as ProductSearchResultModelWithVariants[],
  //       currentPage: currentPage,
  //       pageItem: pageItem,
  //       position: position ?? 0,
  //       storeId: myStoreData?.storeId,
  //       currency: true,
  //       ShippingTier: !pickup ? GTMLabels?.firstTime : GTMLabels?.pickup,
  //       totalCount: Number(cartPricing?.subTotal),
  //       fulfillment_option: !pickup ? GTMLabels?.DFS : GTMLabels?.BOPIS,
  //     });
  // }, [pickup]);

  const sendBeginCheckout = () => {
    const productData = productlineitems?.map((lineItem) => {
      const products = {
        ...lineItem?.Product,
        quantity: lineItem?.Quantity,
        BasePrice: lineItem?.UnitPrice,
        listPrice: lineItem?.UnitPrice,
      };
      return products;
    });

    sendProductsPromotion({
      eventName: GTM_EVENT?.beginCheckout,
      data: productData as unknown as ProductSearchResultModelWithVariants[],
      currentPage: currentPage,
      pageItem: pageItem,
      position: position ?? 0,
      storeId: myStoreData?.storeId,
      currency: true,
      ShippingTier: !pickup ? GTMLabels?.firstTime : GTMLabels?.pickup,
      totalCount: Number(cartPricing?.subTotal),
      fulfillment_option: !pickup ? GTMLabels?.DFS : GTMLabels?.BOPIS,
    });
  };

  return { sendBeginCheckout };
}
