import { LinkField, Link as JssLink, LinkProps, Link } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { createContext, useContext } from 'react';
import useExperienceEditor from '../../hooks/useExperienceEditor';
import { normalizeLinkField } from 'lib/utils/url-formatter';
import NextLink from 'next/link';

interface CustomLinkProps extends Omit<LinkProps, 'field'> {
  field?: LinkField;
  className?: string;
  hideLinkInEE?: boolean;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  buttonClickHandler?: () => void;
  editable?: boolean;
  type?: string;
  attr?: string;
  asButton?: boolean;
  ariaExpanded?: boolean;
  role?: string;
  tabIndex?: number;
  prefetch?: boolean;
  isVisualFilter?: boolean;
}

/** Context to determine whether we are currently inside of a link and thus have a nested link scenario which is invalid */
const LinkContext = createContext<boolean>(false);

const LinkHelper: React.FC<React.PropsWithChildren<CustomLinkProps>> = ({
  field,
  className,
  children,
  hideLinkInEE,
  onClick,
  editable,
  type,
  attr,
  asButton,
  buttonClickHandler,
  role,
  ariaExpanded,
  tabIndex,
  prefetch,
  showLinkTextWithChildrenPresent,
  isVisualFilter,
}) => {
  const isEE = useExperienceEditor();

  const isNestedLink = useContext(LinkContext);

  // Process the href,querystring, and other attributes before rendering
  const processedField = normalizeLinkField(field?.value);

  if (!field) {
    return <></>;
  }
  if (isEE) {
    return (
      <>
        {!hideLinkInEE && (
          <JssLink
            aria-label={field?.value?.text}
            field={field}
            role={role}
            tabIndex={tabIndex}
            aria-expanded={ariaExpanded}
            className={className}
            editable={editable}
            showLinkTextWithChildrenPresent={showLinkTextWithChildrenPresent}
          ></JssLink>
        )}
        <LinkContext.Provider value={true}>{children}</LinkContext.Provider>
      </>
    );
  }
  // If we're already inside a link, a link inside a link is invalid and causes a hydration error because the browser renders it differently.

  if (isNestedLink) {
    if (asButton === true) {
      return !isEE ? (
        <button
          aria-label={field?.value?.text}
          tabIndex={tabIndex}
          onClick={buttonClickHandler}
          className={className}
          aria-expanded={ariaExpanded}
        >
          {field?.value?.text}
        </button>
      ) : (
        <JssLink
          role={role}
          tabIndex={tabIndex}
          aria-label={field?.value?.text}
          field={processedField}
          aria-expanded={ariaExpanded}
          className={className}
          editable={editable}
          showLinkTextWithChildrenPresent={showLinkTextWithChildrenPresent}
        />
      );
    } else {
      return (
        <span
          aria-label={field?.value?.text ?? field?.value?.href}
          data-attr={attr}
          tabIndex={tabIndex}
          className={className}
          onClick={onClick}
        >
          {children}
        </span>
      );
    }
  }

  const linkContent = (
    <LinkContext.Provider value={true}>
      {!children || showLinkTextWithChildrenPresent ? (
        <span data-attr={attr}>{processedField.text}</span>
      ) : null}
      {children}
    </LinkContext.Provider>
  );
  return (
    <>
      {field?.value?.href && field?.value?.href?.length > 0 ? (
        !isVisualFilter ? (
          <NextLink
            aria-label={field?.value?.text ?? field?.value?.href}
            data-attr={attr}
            tabIndex={tabIndex}
            role={role}
            aria-expanded={ariaExpanded}
            type={type}
            className={className}
            onClick={onClick}
            href={{
              pathname: processedField.linktype !== 'anchor' ? processedField.href : '', // Need to add condition for anchor links to avoid in the Next Link
              query: processedField.querystring,
              hash: processedField.anchor,
            }}
            target={processedField.target}
            title={processedField.title || processedField.text}
            // PSP-4078: Change default to false, prefetching many uncached pages
            // causes unacceptably slow performance.
            prefetch={prefetch ?? false}
          >
            {linkContent}
          </NextLink>
        ) : (
          // Using previous JSS Link as onClick functionality of VisualFilter is not working with NextJS Link.
          // TODO: to commonaize a single source code for Link
          <Link
            aria-label={field?.value?.text ?? field?.value?.href}
            data-attr={attr}
            tabIndex={tabIndex}
            role={role}
            aria-expanded={ariaExpanded}
            type={type}
            field={{
              value: processedField,
            }}
            className={className}
            onClick={onClick}
            editable={editable}
          >
            {linkContent}
          </Link>
        )
      ) : (
        linkContent
      )}
    </>
  );
};
export default LinkHelper;
