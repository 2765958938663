import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { breadcrumbVariants } from 'tailwindVariants/components/breadcrumbTailwindVariant';
import clsx from 'clsx';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import IconHelper from 'src/helpers/commonComponents/IconHelper';

interface SiblingItem {
  breadcrumbTitle?: { value: string };
  title: { value: string };
  url: { path: string };
  id: string;
}

interface BreadcrumbData {
  siblings: {
    children: {
      results: SiblingItem[];
    };
  };
}

interface OnlyBreadCrumbSiblingsProps {
  gqldata: BreadcrumbData;
  siteName: string;
  breadCrumbTitle?: Field<string>;
  pageTitle?: Field<string>;
  formattedItemId: string;
}

interface IconProps {
  sitename?: string;
  className?: string;
}

//Tailwind Variants Styles
const {
  breadcrumbText,
  breadcrumbContainer,
  ancestorsWrapper,
  siblingsWrapper,
  siblingsList,
  siblingItem,
  siblingLink,
  breadCrumbSiblingsMenu,
} = breadcrumbVariants({
  device: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

const LinkIcon = ({ className }: IconProps): JSX.Element => {
  return (
    <span className={className}>
      <IconHelper icon={'breadcrumb-down'} className="[&>svg>path]:fill-color-text-brand-1" />
    </span>
  );
};

const getSiblings = (siblings: SiblingItem[], currentItemId: string): SiblingItem[] => {
  return siblings?.filter((sibling) => sibling.id !== currentItemId);
};

const OnlyBreadCrumbSiblings: React.FC<OnlyBreadCrumbSiblingsProps> = ({
  gqldata,
  siteName,
  breadCrumbTitle,
  pageTitle,
  formattedItemId,
}) => {
  const { siblings } = gqldata;
  const filteredSiblings = getSiblings(siblings?.children?.results, formattedItemId);
  const title = breadCrumbTitle?.value || pageTitle?.value;
  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef<HTMLLIElement>(null);

  const handleToggle = () => {
    setIsVisible((prev) => !prev);
  };

  // Handle clicks outside of the component
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (contentRef.current && !contentRef.current.contains(event.target as Node)) {
        setIsVisible(false);
      }
    };

    if (isVisible) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => document.removeEventListener('click', handleClickOutside);
  }, [isVisible]);
  return (
    <div>
      {/* Breadcrumb Rendering */}
      <nav aria-label="breadcrumb" className={breadcrumbContainer()} ref={contentRef}>
        <ul className={ancestorsWrapper()}>
          <li className={clsx(ancestorsWrapper(), 'relative', 'flex')} ref={contentRef}>
            <span
              tabIndex={0}
              className={clsx(breadcrumbText(), breadCrumbSiblingsMenu())}
              onClick={(e) => {
                e.preventDefault();
                handleToggle();
              }}
              onKeyDown={(e) => e.key === 'Enter' && handleToggle()}
              role={'button'}
              aria-expanded={isVisible}
            >
              {title}
              {filteredSiblings?.length > 0 && (
                <LinkIcon sitename={siteName} className={clsx(isVisible && 'rotate-180')} />
              )}
            </span>

            {/* Siblings Rendering */}
            {filteredSiblings?.length > 0 && isVisible && (
              <div className={siblingsWrapper()}>
                <ul className={siblingsList()}>
                  {filteredSiblings?.map((sibling, _index) => (
                    <li key={sibling?.id} className={siblingItem()}>
                      <Link
                        aria-label={sibling?.breadcrumbTitle?.value || sibling?.title?.value}
                        href={sibling?.url?.path}
                        className={clsx(siblingLink(), 'block hover:no-underline')}
                        tabIndex={0}
                        onKeyDown={(e) => e.stopPropagation()}
                        onClick={() => setIsVisible(false)}
                      >
                        {sibling?.breadcrumbTitle?.value || sibling?.title?.value}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default OnlyBreadCrumbSiblings;
