import { Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { useAutoshipContext } from 'lib/context/AutoshipComponentContext';
import { DateTime } from 'luxon';
import React from 'react';
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import { nextDeliveryModalTailwindVariant } from 'tailwindVariants/components/nextDeliveryModalTailwindVariant';

interface NextDeliveryProps {
  fields: {
    title?: Field<string>;
    description?: Field<string>;
    warningHeading?: Field<string>;
    NextDeliveryText?: Field<string>;
    ctaText?: Field<string>;
    button?: Field<string>;
  };
}
const NextDelivery = ({ fields }: NextDeliveryProps) => {
  const { base, title, description, button, warningHeading, buttonWrapper } =
    nextDeliveryModalTailwindVariant({
      size: { initial: 'mobile', lg: 'desktop' },
    });
  const { autoshipContextData, setAutoshipContextData } = useAutoshipContext();
  const handleModalClose = () => {
    setAutoshipContextData({
      ...autoshipContextData,
      isSkippedSuccessfully: false,
      nextDeliveryDate: '',
      skippedDeliveryDate: '',
      updatedNextDeliveryDate: autoshipContextData?.nextDeliveryDate,
    });
  };
  if (fields === undefined || fields === null) return <></>;

  return (
    <ModalWrapper
      showCloseButtonInModalHeader={false}
      closeModalOnOverlayClick={true}
      onCloseClick={handleModalClose}
      showModal={
        !!autoshipContextData?.isSkippedSuccessfully &&
        !!autoshipContextData?.nextDeliveryDate &&
        !!autoshipContextData?.skippedDeliveryDate &&
        !!autoshipContextData?.subscriptionId
      }
      customPopup={true}
      popupWidth="max-w-[997px]"
      popupSpacing="p-0"
    >
      <div className={base()}>
        <RichText className={title()} field={fields?.title} />
        <div>
          <RichTextHelper
            className={description()}
            updatedField={{
              value:
                fields?.description?.value?.replace(
                  '{date}',
                  autoshipContextData?.skippedDeliveryDate ?? ''
                ) || '',
            }}
            field={fields?.description}
          />
          <RichTextHelper
            className={warningHeading()}
            updatedField={{
              value:
                fields?.warningHeading?.value?.replace(
                  '{nextDate}',
                  DateTime.fromISO(autoshipContextData?.nextDeliveryDate ?? '', { zone: 'utc' })
                    .toLocal()
                    .toFormat('MM/dd/yyyy')
                ) || '',
            }}
            field={fields?.warningHeading}
          />
        </div>

        <div className={buttonWrapper()}>
          {fields?.ctaText?.value && (
            <button aria-label="cancel" className={button()} onClick={handleModalClose}>
              {fields?.ctaText?.value}
            </button>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default NextDelivery;
