//global
import React from 'react';
import {
  Field,
  ImageField,
  NextImage,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
//local
import { PSP } from 'models/PetSuppliesPlus.Model';
//lib
import { ComponentProps } from 'lib/component-props';
import { useTheme } from 'lib/context/ThemeContext';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import { iconWithDescriptionTailwindVariant } from 'tailwindVariants/components/iconWithDescriptionTailwindVariant';
export type IconWithDescriptionProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.IconWithDescription.Fields.IconWithDescription;

export type Card = {
  cardItem: {
    fields: {
      title: Field<string>;
      description: Field<string>;
      icon: ImageField;
      iconOnHover: ImageField;
    };
  };
};

//single card
const Card: React.FC<Card> = ({ cardItem }) => {
  const { themeName } = useTheme();
  const { card, cardContentWrapper, cardDescription, cardTitle, cardIcon, img, cardIconHovered } =
    iconWithDescriptionTailwindVariant({
      device: { initial: 'mobile', lg: 'desktop' },
      site: themeName,
    });
  return (
    <div className={card()}>
      <div className={cardIcon()}>
        <NextImage field={cardItem?.fields?.icon} className={img()} priority />
      </div>
      <div className={cardIconHovered()}>
        <NextImage field={cardItem?.fields?.iconOnHover} className={img()} priority />
      </div>
      <div className={cardContentWrapper()}>
        <RichTextHelper tag="h3" field={cardItem?.fields?.title} className={cardTitle()} />
        <RichTextHelper
          tag={'p'}
          field={cardItem?.fields?.description}
          className={cardDescription()}
        />
      </div>
    </div>
  );
};

//main component
const IconWithDescription: React.FC<IconWithDescriptionProps> = ({ fields, params }) => {
  const { themeName } = useTheme();
  const { base, contentWrapper, cardWrapper, titleWrapper, descriptionWrapper } =
    iconWithDescriptionTailwindVariant({
      device: { initial: 'mobile', lg: 'desktop' },
      site: themeName,
    });
  //return empty fregment if there is no data
  if (fields === undefined || fields === null) return <></>;
  return (
    <div className={base({ className: params?.Styles ?? '' })}>
      <div className={contentWrapper()}>
        <RichTextHelper tag="h2" field={fields?.title} className={titleWrapper()} />
        <RichTextHelper tag="p" field={fields?.description} className={descriptionWrapper()} />
      </div>
      <div className={cardWrapper()}>
        {/**fetching the list */}
        {fields?.iconWithDescriptionList?.map((cardItem, index) => {
          return <Card cardItem={cardItem} key={index} />;
        })}
      </div>
    </div>
  );
};

export default withDatasourceCheck()<IconWithDescriptionProps>(IconWithDescription);
