import React, { useState, useEffect } from 'react';
import { LoadScript } from '@react-google-maps/api';
import { Field, ImageField, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import StoreLocatorPage from './StoreLocatorPage';
import StoreLocatorGlobalPopup from './StoreLocatorGlobalPopup';
import { Cookies } from 'react-cookie';
import {
  FilterGeo,
  PageController,
  SearchResultsInitialState,
  WidgetDataType,
  useSearchResults,
  widget,
} from '@sitecore-search/react';
import { convertMilesToKM } from 'src/utils/milestokm';
import { useOcSelector } from 'src/redux/ocStore';
import { GTM_EVENT } from 'src/helpers/Constants';
import { sendStoreGTMData } from 'src/utils/sendGTMEvent';

export interface StoreProps {
  xp_storeimage?: string;
  QuantityAvailable?: number;
  city: string;
  companyname: string;
  xp_storehours: {
    CloseTime?: string;
    Day?: number;
    OpenTime?: string;
  }[];
  country: string;
  firstname: string;
  id: string;
  location: string;
  Location: {
    City: string; // Data from myTime
    StreetAddress: string; // Data from myTime
    State: string; // Data from myTime
    ZipCode: string; // Data from myTime
    PhoneNumber: string; // Data from myTime
    PspStoreId: string;
  };
  phone: string;
  source_id: string;
  state: string;
  storeid: string;
  street1: string;
  xp_bopis: boolean;
  xp_dfs: boolean;
  xp_latitude: string;
  xp_longitude: string;
  xp_onlinebooking: boolean;
  xp_storeservices: number[];
  xp_storetype: number;
  zip: string;
  StoreItemId: string;
  StoreLocatorRemainingOpenTime: string;
  xp_onlinebookingcompany: string;
  xp_onlinebookinglocation: string;
  PspStoreId: string;
}
export interface StoreLocatorConfig {
  headerText?: Field<string>;
  servicesText?: Field<string>;
  storeLocatorRemainingOpenTimeText?: Field<string>;
  afterStoreChangeUrl?: Field<string>;
  rfkid?: Field<string>;
  minCharactersForSearch?: Field<string>;
  jobOpportunitiesText?: Field<string>;
  viewStorePageButtonText?: Field<string>;
  searchboxPlaceholderText?: Field<string>;
  searchboxText?: Field<string>;
  jobOpportunitiesURL?: LinkField;
  myStoreText?: Field<string>;
  shopThisStoreText?: Field<string>;
  noSearchResultsFoundText?: Field<string>;
  requiredText?: Field<string>;
  loadMoreButtonText?: Field<string>;
  loadingNearbyStoresText?: Field<string>;
  storePawIcon?: ImageField;
  myStoreIcon?: ImageField;
  myLocationPointIcon?: ImageField;
  callToInquire?: Field<string>;
  phoneIcon?: ImageField;
  noofItemsPerPageText?: Field<string>;
  noofItemsToShow?: Field<string>;
  changeText?: Field<string>;
  filterType?: Field<string>;
  numberOfRecords?: Field<string>;
  numberOfRecordsToSkip?: Field<string>;
  libraries?: Field<string>;
  country?: Field<string>;
  myStoreFirst?: Field<boolean>;
  defaultLatitude?: Field<number>;
  defaultLongitude?: Field<number>;
  mapZoomLevel?: Field<number>;
}
export interface StoreData {
  storeid?: string;
  LatPos?: number;
  LngPos?: number;
}

export type StoreLocatorProps = ComponentProps & {
  fields?: StoreLocatorConfig;
  params?: {
    [key: string]: string;
  };
  isModal?: boolean;
  isCareerVariant?: boolean;
};
const GenericStoreLocator = ({ fields, params, isModal, isCareerVariant }: StoreLocatorProps) => {
  const [address, setAddress] = useState('');
  const cookies = new Cookies();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedSuggestions, setSelectedSuggestions] = useState<any>({ Keywords: '' });
  const [stores, setStores] = useState<StoreProps[]>([]);
  const [isErrorInStoreData, setIsErrorInStoreData] = useState<boolean>(false);
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const [coordinates, setCoordinates] = useState({
    lat: cookies.get('MyLatitude') || myStoreData?.latitude,
    lng: cookies.get('MyLongitude') || myStoreData?.longitude,
  });
  const circleRadius = Number(myStoreData?.globalRadiusInMiles) || 0;
  useEffect(() => {
    if (myStoreData?.globalRadiusInMiles) {
      const geoFilter = new FilterGeo(
        'location',
        `${convertMilesToKM(myStoreData?.globalRadiusInMiles)}km`
      );
      query.getRequest().resetSearchFilter().setSearchFilter(geoFilter);
    }
  }, [myStoreData?.globalRadiusInMiles]);
  useEffect(() => {
    if (coordinates?.lat && coordinates?.lng) {
      if (fields?.numberOfRecords?.value) {
        onResultsPerPageChange({ numItems: Number(fields?.numberOfRecords?.value) });
      }
      PageController.getContext().setGeo({
        location: { lat: Number(coordinates?.lat), lon: Number(coordinates?.lng) },
      });
    }
  }, [coordinates]);
  useEffect(() => {
    if (!(cookies.get('MyLatitude') && cookies.get('MyLongitude'))) {
      setCoordinates({
        lat: Number(myStoreData?.latitude),
        lng: Number(myStoreData?.longitude),
      });
    }
  }, [myStoreData?.latitude, myStoreData?.longitude]);
  type InitialState = SearchResultsInitialState<'itemsPerPage' | 'sortType'>;
  const {
    actions: { onResultsPerPageChange },
    queryResult: {
      isFetching,
      isLoading,
      data: { total_item: totalItems = 0, content: storesList = [] } = {},
    },
    query,
  } = useSearchResults<StoreProps, InitialState>({
    state: {
      itemsPerPage: Number(fields?.numberOfRecords?.value),
      sortType: 'near_by_distance_asc',
    },
  });
  const onLoadMoreClick = () => {
    onResultsPerPageChange({ numItems: stores?.length + Number(fields?.numberOfRecords?.value) });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelect = async (value: any) => {
    setAddress(value?.keyword);
    if (value && value?.isError) {
      setIsErrorInStoreData(true);
      setStores([]);
    } else if (value?.keyword) {
      sendStoreGTMData({ eventName: GTM_EVENT?.storeLocator });
      setIsErrorInStoreData(false);

      setSelectedSuggestions({
        Keywords: value?.keyword,
      });
      if (!(coordinates?.lat === value.lat && coordinates?.lng === value.lng)) {
        setStores([]);
      }

      if (
        coordinates?.lat === value.lat &&
        coordinates?.lng === value.lng &&
        stores?.length === 0 &&
        storesList?.length > 0
      ) {
        setStores(storesList);
      }
      //TODO: No need to do anything when same latitude and longitude
      else if (coordinates?.lat === value.lat && coordinates?.lng === value.lng) {
      } else {
        setCoordinates({ lat: value.lat, lng: value.lng });
      }
    }
  };
  const options = {
    types: ['(regions)'],
    componentRestrictions: {
      country: fields?.country?.value,
    },
    bounds: {
      //This calculates the radius and this will be in static form only
      east: coordinates.lng + (circleRadius / 111000) * Math.cos(coordinates.lat * (Math.PI / 180)),
      west: coordinates.lng - (circleRadius / 111000) * Math.cos(coordinates.lat * (Math.PI / 180)),
      north: coordinates.lat + circleRadius / 111000,
      south: coordinates.lat - circleRadius / 111000,
    },
  };

  useEffect(() => {
    if (!(isLoading || isFetching)) {
      setStores(storesList);
      if (storesList?.length <= 0 && selectedSuggestions?.Keywords?.length > 0) {
        setIsErrorInStoreData(true);
      }
    }
  }, [isLoading, isFetching]);

  if (fields === undefined || fields === null) return <></>;
  return (
    <div className={`${params?.Styles ?? ''}`}>
      {isModal ? (
        <StoreLocatorGlobalPopup
          fields={fields || {}}
          options={options}
          handleSelect={handleSelect}
          address={address}
          setAddress={setAddress}
          selectedSuggestions={selectedSuggestions}
          stores={stores}
          coordinates={coordinates}
          isStoreLoading={isLoading || isFetching}
          setIsErrorInStoreData={setIsErrorInStoreData}
          isErrorInStoreData={isErrorInStoreData}
          setStores={setStores}
          totalStores={totalItems}
          onLoadMoreClick={onLoadMoreClick}
          setSelectedSuggestions={setSelectedSuggestions}
        />
      ) : (
        <LoadScript
          googleMapsApiKey={process.env.NEXT_PUBLIC_GOOGLE_API_KEY || ''}
          libraries={
            fields?.libraries?.value
              ? JSON.parse(fields?.libraries?.value.replace(/'/g, '"'))
              : ['places']
          }
        >
          <StoreLocatorPage
            fields={fields || {}}
            isCareerVariant={isCareerVariant}
            options={options}
            handleSelect={handleSelect}
            address={address}
            setAddress={setAddress}
            selectedSuggestions={selectedSuggestions}
            stores={stores}
            coordinates={coordinates}
            isStoreLoading={isLoading || isFetching}
            setIsErrorInStoreData={setIsErrorInStoreData}
            isErrorInStoreData={isErrorInStoreData}
            totalStores={totalItems}
            onLoadMoreClick={onLoadMoreClick}
          />
        </LoadScript>
      )}
    </div>
  );
};
const GenericStoreLocatorWidget = widget(
  GenericStoreLocator,
  WidgetDataType.SEARCH_RESULTS,
  'store'
);

export default GenericStoreLocatorWidget;
