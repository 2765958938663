import React from 'react';
import useDictionary from 'src/hooks/useDictionary';
import { errorLabelTailwindVariant } from 'tailwindVariants/components/errorLabelTailwindVariant';

export interface ErrorLabelProps {
  errorMessage: string;
  isDictionaryKey?: boolean;
}
const ErrorLabel = ({ errorMessage, isDictionaryKey }: ErrorLabelProps) => {
  const { title } = errorLabelTailwindVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const { getDictionaryValue } = useDictionary();
  return (
    <>
      <span id="payment-error-label" className={title()}>
        {isDictionaryKey ? getDictionaryValue(errorMessage) : errorMessage}
      </span>
    </>
  );
};

export default ErrorLabel;
