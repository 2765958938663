import { CheckIcon } from '@radix-ui/react-icons';
import {
  AccordionFacets,
  FacetItem,
  RangeFacet,
  SearchResultsFacetValueRange,
} from '@sitecore-search/ui';

import styles from './styles.module.css';
import {
  SearchResponseFacet,
  SearchResponseFacetItem,
  useSearchResultsSelectedFacets,
  useSearchResultsSelectedFilters,
} from '@sitecore-search/react';
import type { SearchResultsStoreTextSelectedFacet } from '@sitecore-search/widgets/dist/esm/searchResults/types';
import useDictionary from 'src/hooks/useDictionary';
import { FacetSetting } from 'components/Search/ProductListing';
import searchResultsTailwind from './SearchResultsTailwind';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import { SearchResultsWidget } from '@sitecore-search/react/dist/esm/types';
import { FacetWrapper, FacetWrapperContext } from './PspFacetWrapper';

export interface PspFacetProps {
  facet: SearchResponseFacet;
  actions: SearchResultsWidget['ActionProps'];
  settings?: FacetSetting;
}

const { facetRoot, facetIcon, facetLabel, clearLabel, accordionWrapper } = searchResultsTailwind({
  size: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

export const PspFacet = ({ facet, actions, settings }: PspFacetProps) => {
  // PSP-1902 When facet only has single value, hide it.
  if (facet?.value?.length <= 1) {
    return <></>;
  }
  return (
    <AccordionFacets.Facet
      facetId={facet.name}
      key={facet.name}
      className={facetRoot({ className: 'border-b-[0px]' })}
    >
      <PspFacetHeader facet={facet} actions={actions} settings={settings} />
      <AccordionFacets.Content>
        {facet.name === 'price' ? (
          <PriceFacet facet={facet} actions={actions} settings={settings} />
        ) : (
          <StandardFacet facet={facet} actions={actions} settings={settings} />
        )}
      </AccordionFacets.Content>
    </AccordionFacets.Facet>
  );
};

function PspFacetHeader({ facet, actions, settings }: PspFacetProps) {
  const selectedFacets = useSearchResultsSelectedFacets();
  const selectedFacetIds = selectedFacets.map((x) => x.id);
  const isFacetSelected = selectedFacetIds.includes(facet.name);
  const dictionary = useDictionary();
  return (
    <AccordionFacets.Header className={'flex flex-col justify-between'}>
      <AccordionFacets.Trigger className={styles['sitecore-accordion-trigger'] + ' group'}>
        <span className={facetLabel()}>{settings?.facetLabelOverride || facet.label}</span>
        {isFacetSelected ? (
          <span
            className={clearLabel()}
            // This is a span instead of a button because we are already inside a button
            onClick={(e) => {
              e.stopPropagation();
              return actions.onRemoveFilter({ facetId: facet.name, type: 'range' });
            }}
          >
            {dictionary.getDictionaryValue('ClearFacetPLP', 'Clear')}
          </span>
        ) : null}
        <IconHelper className={facetIcon()} icon={'chevron-down'} />
      </AccordionFacets.Trigger>
    </AccordionFacets.Header>
  );
}

export const FACET_VALUES_PER_PAGE = 8;

const StandardFacet = ({ facet, settings }: PspFacetProps) => {
  const selectedFacetsFromApi = useSearchResultsSelectedFilters();
  const selectedTextFacets = selectedFacetsFromApi
    .filter((x) => x.type === 'text' && x.facetId === facet.name)
    .map((x) => (x as SearchResultsStoreTextSelectedFacet).facetValueText);

  const getItemText = (x: SearchResponseFacetItem) =>
    settings?.facetValueOverrideMap[x.text] || x.text;

  const { facetCheckbox, facetItemLabel, facetCount, accordionItemWrapper } = searchResultsTailwind(
    {
      size: {
        initial: 'mobile',
        lg: 'desktop',
      },
    }
  );

  return (
    <FacetWrapper
      items={facet?.value}
      selectedFacetValues={selectedTextFacets}
      getValue={(x) => x.text}
      getText={getItemText}
    >
      <ul className={accordionWrapper()}>
        <FacetWrapperContext.Consumer>
          {(context) => {
            {
              return context.items.map((facetValue: SearchResponseFacetItem, index) => {
                const facetValueText = getItemText(facetValue);
                return (
                  <FacetItem
                    {...{
                      index,
                      facetValueId: facetValue.id,
                    }}
                    key={facetValue.id}
                    data-key={facetValue.id}
                    className={styles['sitecore-accordion-item']}
                  >
                    {/* This fragment is needed because otherwise react complains about a missing key.
                      Maybe this is only intended to have 1 child item, but we aren't getting a warning about it. */}
                    <>
                      <AccordionFacets.ItemCheckbox className={facetCheckbox()}>
                        <AccordionFacets.ItemCheckboxIndicator
                          className={styles['sitecore-accordion-item-checkbox-indicator']}
                        >
                          <CheckIcon />
                        </AccordionFacets.ItemCheckboxIndicator>
                      </AccordionFacets.ItemCheckbox>

                      <div className={accordionItemWrapper()}>
                        <AccordionFacets.ItemLabel className={facetItemLabel()}>
                          {facetValueText}
                        </AccordionFacets.ItemLabel>
                        <AccordionFacets.ItemLabel className={facetCount()}>
                          {facetValue.count && `(${facetValue.count.toLocaleString()})`}
                        </AccordionFacets.ItemLabel>
                      </div>
                    </>
                  </FacetItem>
                );
              });
            }
          }}
        </FacetWrapperContext.Consumer>
      </ul>
    </FacetWrapper>
  );
};

const PriceFacet = ({ facet }: PspFacetProps) => {
  const min = Math.floor(facet?.value?.[0].min);
  const max = Math.floor(facet?.value?.[facet?.value.length - 1].max);
  return (
    <SearchResultsFacetValueRange
      max={max}
      min={min}
      autoAdjustValues={false}
      className={styles['sitecore-range-facet-root']}
    >
      <RangeFacet.Track className={styles['sitecore-range-facet-track']}>
        <RangeFacet.Range className={styles['sitecore-range-facet-range']} />
      </RangeFacet.Track>
      <RangeFacet.Start className={styles['sitecore-range-facet-start']}>
        {(value) => <span>${value}</span>}
      </RangeFacet.Start>
      <RangeFacet.End className={styles['sitecore-range-facet-end']}>
        {(value) => <span>${value}</span>}
      </RangeFacet.End>
    </SearchResultsFacetValueRange>
  );
};
